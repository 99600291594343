import File from "@/scripts/models/file.model";
export default class Project {

    constructor() {
        this.id = 0;
        this.fullName = '';
        this.shortName = '';
        this.causeId = null;
        this.incentiveLawId = null;
        this.targetAudienceId = null;
        this.projectTypeId = 0;
        this.objective = '';
        this.socialProblem = '';
        this.solution = '';
        this.methodology = '';
        this.targetAudienceDescription = '';
        this.childrenFundId = null;
        this.elderlyFundId = null;
        this.processNumber = '';
        this.publicationDateDOU = '';
        this.pronacNumber = '';
        this.sportingManifestationId = null;
        this.projectPhaseId = 0;
        this.captureStatus = false;
        this.onlyDirectBeneficiaries = false;
        this.directBeneficiariesDescription = '';
        this.directBeneficiaries = '';
        this.indirectBeneficiaries = '';
        this.sameBeneficiaries = null;
        this.goalFrequency = 0;
        this.totalValue = 0;
        this.capturedValue = 0;
        this.captureDateLimit = '';
        this.bankData = null;
        this.hasConterpart = false;
        this.quotaName = '';
        this.quotaValue = 0;
        this.benefits = '';
        this.supporterList = [];
        this.initialFinalDates = [];
        this.initialDate = '';
        this.finalDate = '';
        this.institutionId = null;
        this.published = false;
        this.subdomain = '';
        this.projectAreaId = null;
        this.projectSequelAreaId = null;
        this.maxItemQuantityPerCPF = null;

        this.odsList = [];
        this.donationProductList = [];
        this.ageRangeList = [];
        this.coverageList = [];
        this.scheduleList = [];
        this.goalList = [];
        this.newsList = [];
        this.followerList = [];

        this.documentsCheck = 0;
        this.newsCheck = 0;
        this.galleryCheck = 0;
        this.scheduleCheck = 0;

        this.stepsValidationStatus = {};
        
        this.faviconFile = new File();
        this.metaTitle = "";
        this.metaDescription = "";

        this.signReceipts = [];
    }
}